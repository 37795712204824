@charset "utf-8";

@import "minimal-mistakes/skins/mint"; // skin
@import "minimal-mistakes"; // main partials


@media (prefers-color-scheme: dark) {
    @import "minimal-mistakes/skins/neon";
    @import "minimal-mistakes";
}

